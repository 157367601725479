import { Token } from "james/ledger";
import { MechanismType } from "james/market";

export enum TokenCategory {
  CryptoCurrency = "Crypto Currency",

  RightsToFiatCurrency = "Rights to Fiat Currency",
  RightsToCryptoCurrency = "Rights to Crypto Currency",
  YieldBearingStablecoin = "Yield Bearing Stablecoin",

  // Note: the mismatch below is intentional.
  // In the back we refer to InstrumentStablecoin, Instrument and Digital Instrument.
  // On the UI we have only 'Rights To Instrument' and 'Instrument'
  InstrumentStablecoin = "Rights to an Instrument",
  DigitalInstrument = "Instrument",
  LiquidityPoolShares = "Liquidity Pool Shares",
  Unknown = "Unknown",
}

export const AllTokenCategories: TokenCategory[] = [
  TokenCategory.CryptoCurrency,
  TokenCategory.RightsToFiatCurrency,
  TokenCategory.RightsToCryptoCurrency,
  TokenCategory.YieldBearingStablecoin,
  TokenCategory.InstrumentStablecoin,
  TokenCategory.DigitalInstrument,
  TokenCategory.LiquidityPoolShares,
  TokenCategory.Unknown,
];

export const ModelTypeName = "mesh::ledgerTokenView/Model";

export class Model {
  public ["@type"]: string = ModelTypeName;

  public id = "";

  public ownerID = "";

  public token: Token = new Token();

  public tokenCategory: TokenCategory | "" = "";

  public name = "";

  public issuer = "";

  public publicIconURL = "";

  public mechanismTypes: MechanismType[] = [];

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.ownerID = model.ownerID;
    this.token = new Token(model.token);
    this.tokenCategory = model.tokenCategory;
    this.name = model.name;
    this.issuer = model.issuer;
    this.publicIconURL = model.publicIconURL;
    this.mechanismTypes = model.mechanismTypes;
  }
}
