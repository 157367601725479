import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useValidatedForm } from "hooks/useForm";
import {
  FormState,
  formUpdaterSpecs,
  initialState,
  validationFunc,
} from "views/SignUp/components/Register/components/CompanyClient/useFormState";
import { useNavigate } from "react-router-dom";
import { useFirebaseContext } from "context/Firebase";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { Close as CloseIcon, Error as ResolveIcon } from "@mui/icons-material";
import { CompanyRegisteredName } from "views/SignUp/const";
import { PasswordRevealTextField } from "components/PasswordReveal/PasswordReveal";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import { ValidateEmailDomain, ValidatePassword } from "utilities/validation";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { FirebaseError } from "@firebase/util";

export type CompanyClientRegisterCardProps = {
  isIndividualUser?: boolean;
  setCompanyName: Dispatch<SetStateAction<string>>;
};

export function CompanyClientRegisterCard({
  setCompanyName,
}: CompanyClientRegisterCardProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialTouchedFields: Set<string> = new Set();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    firebaseSignUpWithEmailAndPassword,
    firebaseLoginWithEmailAndPassword,
  } = useFirebaseContext();

  const [openEmailTooltip, setOpenEmailTooltip] = useState(false);
  const [openPasswordTooltip, setOpenPasswordTooltip] = useState(false);
  // validate individual client email and password
  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm(
      validationFunc,
      async (): Promise<FormState> => {
        return {
          email: "",
          password: "",
          companyName: "",
        };
      },
      formUpdaterSpecs,
      initialState,
      initialTouchedFields,
    );

  const { fieldValidations } = validationResult;

  const handleSignUpWithEmail = async () => {
    setLoading(true);
    if (fieldValidations.email || !formState.email) {
      setOpenEmailTooltip(true);
      setLoading(false);
      return;
    }

    if (fieldValidations.password || !formState.password) {
      setOpenPasswordTooltip(true);
      setLoading(false);
      return;
    }

    try {
      Sentry.setUser({
        email: formState.email,
        id: "",
        ip_address: "{auto}",
      });

      localStorage.setItem(CompanyRegisteredName, formState.companyName);

      await firebaseSignUpWithEmailAndPassword(
        formState.email,
        formState.password,
      );
    } catch (e) {
      const err = e as FirebaseError;
      Sentry.captureException(
        `unexpected error signing up user: ${
          err.message ? err.message : err.toString()
        }`,
      );

      switch (err.code) {
        case "auth/email-already-exist":
        case "auth/email-already-in-use":
          try {
            // attempt to sign-in the user
            await firebaseLoginWithEmailAndPassword(
              formState.email,
              formState.password,
            );
          } catch (e) {
            // if there was an error with the sign-in attempt show a snackbar to prompt them
            // to go to so sign-in screen
            enqueueSnackbar(`You are already registered. Please sign in.`, {
              variant: "info",
              action: () => (
                <>
                  <Link
                    sx={(theme) => ({
                      color: theme.palette.text.primary,
                      marginRight: theme.spacing(2),
                      textDecorationColor: theme.palette.text.primary,
                      fontWeight: "bold",
                      "&:hover": {
                        color: theme.palette.text.primary,
                      },
                    })}
                    onClick={() => {
                      closeSnackbar();
                      navigate("/sign-in");
                    }}
                  >
                    Sign In
                  </Link>
                </>
              ),
            });
          }
          break;

        default:
          enqueueSnackbar(
            `unexpected error signing up user: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          break;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formState.password && !fieldValidations.password) {
      setOpenPasswordTooltip(false);
    }

    if (formState.email && !fieldValidations.email) {
      setOpenEmailTooltip(false);
    }
  }, [
    fieldValidations.password,
    fieldValidations.email,
    formState.email,
    formState.password,
  ]);

  const disableButton = () => {
    if (validationInProgress || loading) {
      return true;
    }

    return !formState.password || !formState.email || !formState.companyName;
  };

  return (
    <OnboardingCard
      dataComponentAttribute={JSON.stringify({
        component_id: "sign_up",
        component_business_name: "Sign up",
        component_title: "Welcome to mesh",
        component_driver: InteractionDriver.Join,
      } as DataComponentInfo)}
      linksFooter={
        <Box
          sx={(theme) => ({
            display: "flex",
            paddingBottom: theme.spacing(5.5),
          })}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontSize: "14px",
            })}
            variant="body1"
          >
            Already Registered?
          </Typography>
          <Link
            sx={(theme) => ({
              paddingLeft: theme.spacing(1),
              fontWeight: "bold",
            })}
            onClick={() => {
              navigate("/sign-in");
            }}
            fontSize={"14px"}
            component="button"
            underline="hover"
            color="secondary"
            data-link-info={JSON.stringify({
              content_interaction_id: "sign-in-button",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "already registered - sign in",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.SignIn,
            } as DataLinkInfoType)}
          >
            Sign In
          </Link>
        </Box>
      }
    >
      <VerticalSlide transitionDuration={1 / 6}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 3, 0.5),
          })}
          fontSize="20px"
          fontWeight="600"
          children="Welcome to Mesh!"
        />
      </VerticalSlide>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
        onSubmit={(e) => {
          handleSignUpWithEmail().finally();
          e.preventDefault();
        }}
      >
        <VerticalSlide width="100%" transitionDuration={2 / 6}>
          <TextField
            sx={(theme) => ({
              paddingBottom: theme.spacing(2),
              margin: 0,
            })}
            fullWidth
            label="Company name"
            placeholder={"company name"}
            InputLabelProps={{ shrink: undefined }}
            inputMode={"text"}
            id="signUp-companyClientOnboardingRequest-name-textField"
            value={formState.companyName}
            onChange={(e) => {
              formUpdater.companyName(e.target.value);
              setCompanyName(e.target.value);
            }}
          />
        </VerticalSlide>
        <VerticalSlide width="100%" transitionDuration={3 / 6}>
          <Tooltip
            id="SignUp-RegisterCard-EmailPopper"
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "16px",
                  width: "280px",
                  display: "grid",
                  gridTemplateRows: "auto",
                  color: "black",
                  top: "-23px",
                },
              },
              arrow: {
                sx: {
                  left: "-108px !important",
                },
              },
            }}
            arrow
            open={openEmailTooltip}
            placement="bottom"
            title={
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "20px 1fr 20px",
                    gridColumnGap: (theme) => theme.spacing(0.5),
                    paddingBottom: (theme) => theme.spacing(1),
                  }}
                >
                  <ResolveIcon
                    sx={{
                      width: "22px",
                      height: "22px",
                      color: "black",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    Please Resolve:
                  </Typography>
                  <IconButton
                    id="SignUp-RegisterCard-EmailPopper-Close-Button"
                    onClick={() => setOpenEmailTooltip(false)}
                    aria-label="close"
                    sx={{
                      width: "22px",
                      height: "22px",
                      color: "black",
                    }}
                    size="large"
                  >
                    <CloseIcon
                      id="SignUp-RegisterCard-EmailPopper-Close-Icon"
                      sx={{
                        width: "22px",
                        height: "22px",
                        color: "black",
                      }}
                    />
                  </IconButton>
                </Box>
                <Typography variant="body2">
                  {!formState.email &&
                    !fieldValidations.email &&
                    " - Email cannot be empty"}{" "}
                  {(() => (
                    <>
                      {fieldValidations.email &&
                        ValidateEmailDomain(formState.email).map(
                          (value, idx) => (
                            <Typography key={idx} variant="body2">
                              - {value}
                            </Typography>
                          ),
                        )}
                    </>
                  ))()}
                </Typography>
              </>
            }
          >
            <TextField
              sx={(theme) => ({
                paddingBottom: theme.spacing(2),
                margin: 0,
              })}
              fullWidth
              autoComplete={"off"}
              label="Company email address"
              placeholder={"name@youremail.com"}
              InputLabelProps={{ shrink: undefined }}
              id="signUp-companyClientOnboardingRequest-email-textField"
              value={formState.email}
              inputProps={{ type: "email" }}
              onChange={(e) =>
                formUpdater.email(e.target.value.replace(/\s+/g, ""))
              }
            />
          </Tooltip>
        </VerticalSlide>
        <VerticalSlide width="100%" transitionDuration={4 / 6}>
          <Tooltip
            id="SignUp-RegisterCard-PasswordPopper"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "16px",
                  width: "280px",
                  display: "grid",
                  gridTemplateRows: "auto",
                  color: "black",
                  top: "-23px",
                },
              },
              arrow: {
                sx: {
                  left: "-108px !important",
                },
              },
            }}
            open={openPasswordTooltip}
            placement="bottom"
            title={
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "20px 1fr 20px",
                    gridColumnGap: (theme) => theme.spacing(0.5),
                    paddingBottom: (theme) => theme.spacing(1),
                  }}
                >
                  <ResolveIcon
                    sx={{
                      width: "22px",
                      height: "22px",
                      color: "black",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    Please Resolve:
                  </Typography>
                  <IconButton
                    id="SignUp-RegisterCard-PasswordPopper-Close-Button"
                    onClick={() => setOpenPasswordTooltip(false)}
                    aria-label="close"
                    sx={{
                      width: "22px",
                      height: "22px",
                      color: "black",
                    }}
                    size="large"
                  >
                    <CloseIcon
                      id="SignUp-RegisterCard-PasswordPopper-Close-Icon"
                      sx={{
                        width: "22px",
                        height: "22px",
                        color: "black",
                      }}
                    />
                  </IconButton>
                </Box>
                {(() => (
                  <>
                    {!formState.password &&
                      ValidatePassword(formState.password).map((value, idx) => (
                        <Typography key={idx} variant="body2">
                          - {value}
                        </Typography>
                      ))}
                  </>
                ))()}
                {(() => (
                  <>
                    {formState.password &&
                      fieldValidations.password &&
                      fieldValidations.password.split(",").map((value, idx) => (
                        <Typography key={idx} variant="body2">
                          - {value}
                        </Typography>
                      ))}
                  </>
                ))()}
              </>
            }
          >
            <PasswordRevealTextField
              sx={(theme) => ({
                paddingBottom: theme.spacing(2),
                margin: 0,
              })}
              fullWidth
              label="Password"
              placeholder={"Enter Password"}
              id="signUp-companyClientSignUp-password-textField"
              InputLabelProps={{ shrink: undefined }}
              autoComplete="off"
              value={formState.password}
              onChange={(e) => {
                formUpdater.password(e.target.value);
              }}
            />
          </Tooltip>
        </VerticalSlide>
        <VerticalSlide width="100%" transitionDuration={5 / 6}>
          <Button
            id="signUp-companyClientSignUp-signUpWithEmail-button"
            sx={(theme) => ({
              height: theme.spacing(6),
              boxShadow: 5,
              width: "100%",
              marginBottom: theme.spacing(2),
            })}
            color="primary"
            variant="contained"
            size="large"
            disabled={disableButton()}
            onClick={handleSignUpWithEmail}
            children="Sign Up"
          />
        </VerticalSlide>
      </form>
    </OnboardingCard>
  );
}
